import { applyMiddleware, createStore, compose } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import { connectRouter } from "connected-react-router";
import thunk from "redux-thunk";
import rootReducer from "../reducers";

export const history = createBrowserHistory();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function configureStore(initialState) {
  return createStore(
    connectRouter(history)(rootReducer),
    initialState,
    composeEnhancer(applyMiddleware(thunk, routerMiddleware(history))),
  );
}
