import React from "react";
import { Route, Switch } from "react-router-dom";
import Loadable from "./components/Loadable";
import { LocaleProvider } from "antd";
import svSe from "antd/es/locale-provider/sv_SE";
import "moment/locale/sv";

import { hot } from "react-hot-loader";
import "./style/antd.less";
import "./style/global.less";

// Used to allow branch-names as the 1st param in url paths
const devBranch = process.env.PUBLIC_URL || "";

const App = () => {
  if (window.location.href.includes("insyn.hittahem.se")) {
    window.location.replace("https://insyn.bostadsboxen.bonniernews.se");
  }

  return (
    <LocaleProvider locale={svSe}>
      <Switch>
        <Route
          path={`${devBranch}/light`}
          component={Loadable({
            loader: () => import("./layouts/LightLayout"),
          })}
        />
        <Route
          path={`${devBranch}/user`}
          component={Loadable({
            loader: () => import("./layouts/UserLayout"),
          })}
        />
        <Route
          path={`${devBranch}/information`}
          component={Loadable({
            loader: () => import("./layouts/InfoLayout"),
          })}
        />
        <Route
          path={`${devBranch}/marknadsplats`}
          component={Loadable({
            loader: () => import("layouts/UserLayout"),
          })}
        />
        <Route
          path={`${devBranch || "/"}`}
          component={Loadable({
            loader: () => import("./layouts/InsynLayout"),
          })}
        />
      </Switch>
    </LocaleProvider>
  );
};

export default hot(module)(App);
