import {
  SET_COLLAPSED,
  SET_FILTER,
  CLEAR_FILTER,
  SET_FILTER_DATE,
  SET_FILTER_INPUT,
  SET_FILTER_OFFICES,
  SET_FILTER_PARTNERS,
} from "../actions/types";

import moment from "moment";
import { getValueFromStorage } from "../components/Filter/filter-storage";

const dateFormat = "YYYY-MM-DD";

// collapsed by default on phones
const matchMedia = window.matchMedia("(max-width: 770px)");

const initalState = () => {
  const collapsed = matchMedia.matches;
  const from = getValueFromStorage("from");
  const to = getValueFromStorage("to");
  const date =
    from && to
      ? { start: from, end: to }
      : {
          start: moment()
            .subtract(6, "days")
            .format(dateFormat),
          end: moment().format(dateFormat),
        };
  return {
    collapsed,
    filterUpdated: false,
    date,
    offices: [],
    partners: [],
  };
};

export default (state = initalState(), action) => {
  switch (action.type) {
    case SET_COLLAPSED:
      return { ...state, collapsed: action.state };
    case SET_FILTER:
      return {
        ...state,
        ...action.state,
        filterUpdated: true,
      };
    case CLEAR_FILTER:
      return initalState();
    case SET_FILTER_DATE:
      return { ...state, date: action.state };
    case SET_FILTER_INPUT:
      return { ...state, input: action.state };
    case SET_FILTER_OFFICES:
      return { ...state, offices: action.state };
    case SET_FILTER_PARTNERS:
      return { ...state, partners: action.state };
    default:
      return state;
  }
};
