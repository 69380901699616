import React from "react";
import ReactDOM from "react-dom";
import { AppInsights } from "applicationinsights-js";

// import * as serviceWorker from "./serviceWorker";
// import serviceWorkerConfig from "./serviceWorker.config";
import { configureStore, history } from "./store/configureStore";
import Root from "./containers/Root";

const store = configureStore();

ReactDOM.render(
  <Root store={store} history={history} />,
  document.getElementById("root"),
);

// serviceWorker.register(serviceWorkerConfig);

if (process.env.REACT_APP_AI_INSTRUMENTATION_KEY) {
  AppInsights.downloadAndSetup({
    instrumentationKey: process.env.REACT_APP_AI_INSTRUMENTATION_KEY,
  });
}
