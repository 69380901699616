export const getFilterStorageName = name => `filter-${name}`;

export const setValueToStorage = (name, value) => {
  if (value) {
    localStorage.setItem(getFilterStorageName(name), JSON.stringify(value));
  } else {
    removeValueFromStorage(name);
  }
};

export const getValueFromStorage = name => {
  return JSON.parse(localStorage.getItem(getFilterStorageName(name)));
};

export const removeValueFromStorage = name => {
  localStorage.removeItem(getFilterStorageName(name));
};
